body {
  min-width: 320px;
  padding-bottom: 40px;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}
.dot {
  height: .75rem;
  width: .75rem;
}

.icon-flipped {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

.gradient {
  background: linear-gradient(to right, #20325e 0%, #8CD1DF 100%);
}

.icon {
  fill: white;
  width: 2rem;
  height: 2rem;

  &-blue {
    fill: $t_blue;
    width: 1.4rem;
    height: 1.4rem;
  }
}

.btn.btn-t_button {
  height: auto;
  width: auto;
}

btn.btn-t_button:hover, btn.btn-t_button.hover, .show>.btn-t_button.dropdown-toggle {
    color: $black;
    background-color: $t_blue;
}

.btn-t_button:not(:disabled):not(.disabled).active, .btn-t_button:not(:disabled):not(.disabled):active, .show>.btn-t_button.dropdown-toggle {
    color: $white;
    background-color: $t_blue;
}

.btn-t_button:not(:disabled):not(.disabled).activgit ae:hover, .btn-t_button:not(:disabled):not(.disabled).active.hover, .show>.btn-t_button.dropdown-toggle {
    color: $white;
    background-color: lighten($t_blue, 10%);

}

.nav-tabs {
    padding-left: 15px;
    margin-bottom: 0;
    border: $t_blue;
}
.tab-content {
    border: 1px solid #ddd;
    border-color: $t_g_blue;
    padding: 15px;
}

.tab-content .form-control {
  width:80px;
}

.w_100 {
  width:210px;
}

hr {
  border-top: 1px solid $t_gray;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: $t_hover;
}


.funkyradio div {
  clear: both;
  overflow: hidden;
}

.funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #d1d3d4;
  font-weight: normal;
}

.funkyradio input[type="radio"]:empty {
  display: none;
}

.funkyradio input[type="radio"]:empty ~ label {
  position: relative;
  line-height: 2.0em;
  text-indent: 0em;
  margin-top: 0em;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.funkyradio input[type="radio"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  height: auto;
  text-indent: 3.25em;
  background: $t_button;
  border-radius: 3px 0 0 3px;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label {
  color: $black;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label:before {
  content: '\2715';
  text-indent: 1em;
  color: #c2c2c2;
}

.funkyradio input[type="radio"]:checked ~ label {
  color: $black;
}

.funkyradio input[type="radio"]:checked ~ label:before {
  content: '\2715';
  text-indent: 1em;
  color: #333;
  background-color: #ccc;
}

.funkyradio input[type="radio"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-primary input[type="radio"]:checked ~ label:before {
  color: $white;
  background-color: $t_blue;
}


//###############################################################
//#### DATA-TABLE

.cursor-pointer {
  cursor: pointer;
}

.stripe1 {
  background-color: $white;
}

.stripe2 {
  background-color: $t_ll_gray;
}

.highlighted {
  background: $t_l_button;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  /* justify-content: flex-end; */
  justify-content: flex-start;
}

.page-item.active .page-link {
  z-index: 1;
  color: #FFF;
  background-color: $t_blue;
  border-color: #414850;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $t_blue;
  background-color: #FFF;
  border: 1px solid #DEE2E6;
}

a.my-confirm-class {
  //background: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#009688' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>") no-repeat;
  background: url("/static/src/img/svg_sprite/check-solid.svg") no-repeat;
  color: transparent;
  //content: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#009688' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
}

a.my-cancel-class {
  //background: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'><path fill='#d0021b' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>") no-repeat;
  background: url("/static/src/img/svg_sprite/times-solid.svg") no-repeat;
  color: transparent;
  //content: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'><path fill='#d0021b' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>");
}

.myfieldclass {
  margin-top: 5px;
}

.form-control.dynamic {
  width: 150px;
  display: inline-block;
  margin-right: 12px;
}

td.clickable {
  cursor: pointer;
  color: $t_blue;

}

td.clickable:hover {
  color: $success;
  background-color: $t_l_gray;
}

.nobullets {
  list-style: none;
  margin-top: 5px;
}

.custom-select.wizard, .form-control.wizard {
  width: 200px;
}

.minus-margin {
  margin-left: -60px;
  margin-top: -3px;
}

@media only screen and (min-width: 1200px) {
  .input-group.select-field {
    width: 40%;
  }
}

td.select-checkbox {
  padding: 0;
}

label.label-cell{
    display: block;
    cursor: pointer;
    padding: 1em 0;
    width: 40px;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
  background: url("/static/src/img/svg_sprite/plus-square-regular.svg") no-repeat center center !important;
  color: transparent !important;
  border-radius: 0px !important;
  height: 20px !important;
  width: 20px !important;
  box-shadow: 0 0 0px transparent !important;
  border: none !important;

}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
  background: url("/static/src/img/svg_sprite/minus-square-regular.svg") no-repeat center center !important;
  color: transparent !important;
  border-radius: 0px !important;
  height: 20px !important;
  width: 20px !important;
  box-shadow: 0 0 0px transparent !important;
  border: none !important;
}

.extra-text {
  margin-top: 50px;
}

ul.errorlist{
  background-color: #f8d7da;
  border: 1px solid transparent;
  border-color: #f5c6cb;
  border-radius: .25rem;
  color: #721c24;
  list-style: none;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

//###############################################################
//#### WIZARD

.table.wizard > tbody > tr > td {
  vertical-align: middle;
}

.table.wizard > thead > tr:first-child > td {
    border-top: none;
}

//@keyframes click-wave {
//  0% {
//    height: 40px;
//    width: 40px;
//    opacity: 0.35;
//    position: relative;
//  }
//  100% {
//    height: 200px;
//    width: 200px;
//    margin-left: -80px;
//    margin-top: -80px;
//    opacity: 0;
//  }
//}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 30px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover::before {
  background: #9faab7;
  height: 30px;
  width: 30px;
  position: absolute;
  content:'\2715';
  display: inline-block;
  font-size: 21.66667px;
  text-align: center;
  line-height: 30px;
}

.option-input:checked:hover:after {
  background: $t_blue;
  height: 30px;
  width: 30px;
  position: absolute;
  content:'\2715';
  display: inline-block;
  font-size: 21.66667px;
  text-align: center;
  line-height: 30px;
}

.option-input:checked {
  background: $t_blue;
}
.option-input:checked::before {
  height: 30px;
  width: 30px;
  position: absolute;
  content:'\2715';
  display: inline-block;
  font-size: 21.66667px;
  text-align: center;
  line-height: 30px;
}

.table.wizard tr:hover ~ .option-input:hover::before{
  background-color: red;
}

/* fallback for  IE11 */
.option-input:checked:checked::-ms-check {
  background: url("/static/src/img/svg_sprite/times-solid-white.svg") no-repeat center;
  background-size: 15px;
  color: transparent;
  border: 0px none transparent;
  border-radius: 0px;
  padding: 3px;
}

.option-input:hover::-ms-check {
  background: url("/static/src/img/svg_sprite/times-solid-white.svg") no-repeat center;
  background-size: 15px;
  color: transparent;
  border: 0px none transparent;
  border-radius: 0px;
  padding: 3px;
}

.option-input:checked::after {
  //-webkit-animation: click-wave 0.65s;
  //-moz-animation: click-wave 0.65s;
  //animation: click-wave 0.65s;
  background: $t_blue;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
//.option-input.radio {
//  border-radius: 50%;
//}
//.option-input.radio::after {
//  border-radius: 50%;
//}
